
<template>
    <b-container fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">
            {{ $t('barc_config.application_name_setup') }}
          </h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Sale Date" vid="item_id">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="item_id"
                  slot-scope="{ errors }"
                  >
                  <template v-slot:label>
                    {{$t('barc_config.application_name_en')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.application_id"
                    :options="applicationList"
                    id="item_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}
                    </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Sale Date" vid="sell_date" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="sell_date"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                  </template>
                  <flat-pickr class="form-control"
                    v-model="search.date_from"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></flat-pickr>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Sale Date" vid="sell_date" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="sell_date"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                  </template>
                  <flat-pickr class="form-control"
                    v-model="search.date_to"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></flat-pickr>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-button type="button" variant="primary" @click="searchData">
            {{ $t('globalTrans.search') }}
          </b-button>
        </template>
      </iq-card>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">
                {{ $t('barc_config.application_name_setup') }}
              </h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                {{ $t('globalTrans.add_new') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row>
                  <b-col md="12" class="table-responsive">
                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                      <template v-slot:cell(index)="data">
                        {{ $n(data.index + pagination.slOffset) }}
                      </template>
                      <template v-slot:cell(set_up_date)="data">
                        {{ data.item.set_up_date|dateFormat }}
                      </template>
                      <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status == 1">
                          {{$t('globalTrans.active')}}
                        </span>
                        <span class="badge badge-danger" v-else>
                          {{$t('globalTrans.inactive')}}
                        </span>
                      </template>
                      <template v-slot:cell(action)="data">
                        <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)">
                          <i class="ri-eye-line"></i>
                        </b-button>
                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)">
                          <i class="ri-ball-pen-fill m-0"></i>
                        </b-button>
                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                          <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                        </b-button>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total-rows="pagination.totalRows"
                      @input="searchData"
                    />
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
      </b-modal>
    </b-container>
</template>
<script>
  import Form from './Form'
  import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
  import { applicationNameSetUpList, applicationNameSetUpStatus } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/modal-base-master-list'
  import flatpickr from 'flatpickr'

  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form
    },
    data () {
      return {
        search: {
          application_id: 0,
          date_from: '',
          date_to: ''
        },
        rows: []
      }
    },
    mounted () {
      flatpickr('#datepicker', {})
    },
    computed: {
      applicationList: function () {
        const objectData = this.$store.state.incentiveGrant.commonObj.applicationList.filter(item => item.status === 1)
        return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
        })
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('barc_config.application_name_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('barc_config.application_name_setup') + ' ' + this.$t('globalTrans.modify')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('barc_config.application_name_en'), class: 'text-left' },
          { label: this.$t('barc_config.application_setup_date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'index' },
            { key: 'application_name_bn' },
            { key: 'set_up_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'index' },
            { key: 'application_name' },
            { key: 'set_up_date' },
            { key: 'status' },
            { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
          })
      }
    },
    watch: {
      loadingState: function (newVal, oldVal) {
        if (newVal) {
          this.loadData()
        }
      }
    },
    created () {
      this.loadData()
    },
    methods: {
      searchData () {
        this.loadData()
      },
      edit (item) {
        this.editItemId = item.id
      },
      view (item) {
        this.editItemId = item.id
        this.item = item
      },
      remove (item) {
        this.changeStatus(incentiveGrantServiceBaseUrl, applicationNameSetUpStatus, item, 'incentive_grant', 'applicationList')
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })

        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(incentiveGrantServiceBaseUrl, applicationNameSetUpList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
          } else {
            this.$store.dispatch('setList', [])
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      }
    }
  }
</script>
